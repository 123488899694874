.footer {
    text-align: left;
    max-width: 900px;
    margin: auto;
    padding: 0 10px;
  }
  
  .split-footer {
    display: flex;
    flex-wrap: wrap;
  }
  
  .split-footer .hours {
    max-width: 300px;
    padding: 0 40px 40px 0;
  }
  
  .day-time {
    display: flex;
    justify-content: space-between;
  }

  .split-footer .map {
    max-width: 550px;
  }

  .map iframe {
    max-width: 90vw;
  }
  
  .divide-line {
    background-color: black;
    width: 100%; height: 2px;
    margin: 20px 0;
  }
  
  .address-phone {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .address-phone > a {
    color: black;
    text-decoration: none;
  }

  @media (min-width: 1000px) {
    .footer {
      padding: 0;
    }
  }