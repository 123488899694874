body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Main */

.page-content {
  max-width: 900px;
  text-align: left;
  padding: 40px 10px;
  margin: auto;
}

.tab-header {
  text-align: left;
}

.tab-content {
  text-align: left;
}

@media (min-width: 1000px) {
  .page-content {
    padding: 40px 0;
  }
}